import React from "react";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import PropTypes from "prop-types";
import Container from "../utils/Container";

function CarouselBrands({ title, content, globalBrands }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <BsChevronRight color="#0064bf" size="3em" />,
    prevArrow: <BsChevronLeft color="#0064bf " size="3em" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" py-14 bg-[#fff4d9]">
      {title && (
        <Container>
          <div className="xl:text-2xl text-xl md:text-2xl font-bold leading-[2.5rem] text-[#0f1921] pt-[30px] pb-[70px] md:font-medium">
            <p className="ml-[10px]">{title}</p>
          </div>
        </Container>
      )}
      <Container>
        <Slider {...settings}>
          {globalBrands.map((brandName) => (
            <div key={brandName} className="w-[278]">
              <img src={brandName} alt="" className="w-[150px] h-[70px] mx-auto items-center justify-center" />
            </div>
          ))}
        </Slider>
      </Container>
      {content && (
        <Container>
          <div className=" pt-[70px] w-[86%] font-bold">
            <p className="mb-[24px] text-[#808080] text-[16px]">{content}</p>
          </div>
        </Container>
      )}
    </div>
  );
}
CarouselBrands.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  globalBrands: PropTypes.arrayOf(PropTypes.string),
};
export default CarouselBrands;
